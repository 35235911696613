import React, { Suspense, useEffect } from "react";
import CacheBuster from 'react-cache-buster';
import { version } from '../package.json';
// import Loading from './loading';

import { Helmet } from "react-helmet";
import { BrowserRouter as Router, Route, Switch, useLocation } from "react-router-dom";
import { Container } from "react-bootstrap";
import Imagine from "./components/Imagine.js";
import Logo from "./components/Logo.js";
import Nav from "./components/Nav.js";
import About from "./components/About.js";
import Products from "./components/Products.js";
import Contact from "./components/Contact.js";
import References from "./components/References.js";
import ScrollToTop from "./components/ScrollToTop";
import Offer from "./components/Offer";
import Submited from "./components/Submited";
import "bootstrap/dist/css/bootstrap.min.css";
import Menu from "./components/Menu.js";
import Hamburger from "./components/Hamburger.js";

function App() {
  const isProduction = process.env.NODE_ENV === 'production';
  let isMobile = window.innerWidth < 1280 || window.innerHeight < 750;
  // let location = useLocation()

  // useEffect(() => {
  //   let title = window.location.pathname
  //   document.title = title;
  // }, [location]);

  return (
    <CacheBuster
    currentVersion={version}
    isEnabled={isProduction} //If false, the library is disabled.
    isVerboseMode={false} //If true, the library writes verbose logs to console.
    // loadingComponent={<Loading />} //If not pass, nothing appears at the time of new version check.
  >
      <Router>
        <Helmet>
          <title>Imagine Visuals - 3D Architektur Visualisierung</title>
          <meta
            name="description"
            content="Architektur-Visualisierungen für Vermarktung und Wettbewerbe."
          />
          <meta
            name="keywords"
            content="architektur, visualisierungen, vermarktung, wettbewerbe"
          />
          <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
          <meta name="viewport" content="width=device-width,initial-scale=1.0" />
          <link
            rel="preload"
            href="../img/rapperswil/immobilien-vermarktung-visualisierung-architektur-1c.jpg"
            as="image"
          ></link>
          <link
            rel="preload"
            href="../img/weisslingen/Wettbewerb-Visualisierung-Kiga-Weisslingen-1c.jpg"
            as="image"
          ></link>
          <link rel="preload" href="../img/referenzen.png" as="image"></link>
          <link rel="preload" href="../img/referenzen_v.png" as="image"></link>
          <link rel="preload" href="../img/m_bw.png" as="image"></link>
        </Helmet>
        <div className="App main">
          <Suspense fallback={<div></div>}>
            <Logo useSuspense={false} />
            {isMobile ? <Hamburger onClick={<Menu />} /> : undefined}
            {isMobile ? undefined : <Nav />}
            <ScrollToTop />
            <Container fluid className="main" style={{}}>
              <Switch>
                <Route path="/" exact component={Imagine} title="Index Page"/>
                <Route path="/portfolio" component={Imagine} />
                <Route path="/produkte" component={Products} />
                <Route path="/profil" component={About} />
                <Route path="/referenzen" component={References} />
                <Route path="/offerte" component={Offer} />
                <Route path="/kontakt" component={Contact} />
                <Route path="/gesendet" component={Submited} />
                <Route path="/menu" component={Menu} />
                <Route path="*" component={Imagine} />
              </Switch>
            </Container>
          </Suspense>
        </div>
      </Router>
    </CacheBuster>
  );
}

export default App;
