import React from 'react'
import {Row, Col} from 'react-bootstrap'
import Nav from './Nav.js'

function Menu() {
    
    return (
        <Row className="p-2 mobMenu" style={{height:window.innerHeight-32}}>
            <Nav />
        </Row>
    )
}
export default Menu