import React from 'react'
// import {Col, Image, Button } from 'react-bootstrap'
import {NavLink} from 'react-router-dom'

function OfferButton() {
    let isMobile = window.innerWidth < 1280 || window.innerHeight < 750;

    
    return (
        <NavLink to="/offerte" className={isMobile ? "offerBtnMob buttonAbsoluteMob" : "offerBtn buttonAbsolute"}>
            <div
                className="btnText"
                // style={ window.innerWidth < 800 ? {width:165, height:25} : {width:330, height:50}}
            >Offerte anfordern</div>
        </NavLink>
    )
}

export default OfferButton
