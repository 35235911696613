import React from "react";
import { Helmet } from "react-helmet";
import { Row, Col, Image, Media } from "react-bootstrap";

import OfferButton from "./OfferButton";

function References() {
  let isMobile = window.innerWidth < 1280 || window.innerHeight < 750;

  return (
    <Row
      className="bg align-items-center justify-content-center"
      style={{ height: window.innerHeight - 32 }}
    >
      <Helmet>
        <title>Referenzen</title>
        <meta name="description" content="Unsere Referenzen" />
        <meta name="keywords" content="referenzen" />
      </Helmet>
      {isMobile ? undefined : <OfferButton />}
      <Media style={{ maxWidth: 1600 }} className={isMobile ? "" : "pb-5"}>
        <Image
          fluid
          className=""
          src={
            window.innerHeight < window.innerWidth
              ? "../img/referenzen.png"
              : "../img/referenzen_v.png"
          }
          alt="referenzen"
        />
      </Media>
    </Row>
  );
}

export default References;