import React from "react";
import { Row, Col } from "react-grid-system";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { Image, Media } from "react-bootstrap";
import { NavLink } from "react-router-dom";

import OfferButtonKontakt from "./OfferButtonKontakt";

function Contact() {
  const { t, i18n } = useTranslation();

  let isMobile = window.innerWidth < 1280 || window.innerHeight < 750;

  return (
    <Row
      className={isMobile ? "bg" : "bg"}
      style={{ height: window.innerHeight - 32 }}
    >
      <Helmet>
        {/* <title>{t("nav.contact")} - Imagine Visuals - {t("content.imagine")}</title> */}
        <title>{t("nav.contact")}</title>
        <meta name="description" content="Kontakt" />
        <meta name="keywords" content={t("content.keywords")} />
      </Helmet>

      <Col>
        {isMobile ? undefined : <OfferButtonKontakt />}

        <Row style={{ height: window.innerHeight - 32 }}>
          <Col
            xs={12}
            xl={6}
            className={isMobile ? "textMob" : "text"}
            style={isMobile ? { display: "none" } : {}}
          >
            <div
              style={{ position: "relative", height: "100%" }}
              className={isMobile ? "hidden" : ""}
            >
              <div style={{ position: "absolute", bottom: 0, left: 0 }}>
                <Image
                  fluid
                  className="portrait"
                  src="../img/m_bw2.png"
                  alt=""
                />
              </div>
            </div>
          </Col>
          <Col
            xs={12}
            xl={6}
            className={isMobile ? "textBigMob pt-4 p-0 m-0" : "textBig"}
          >
            <div
              className={
                isMobile
                  ? "text-center form-container-mob"
                  : "center text-left p-5"
              }
            >
              <h2 class="pb-2">
                <i>Imagine Visuals</i>
              </h2>
              <div class="pb-3">
                Niederdorfstrasse 3 <br />
                8001 Zürich, Schweiz <br />
              </div>
              {/* <br /> */}
              <a
                className="link font-weight-bold"
                href="mailto:info@imaginevisuals.com"
              >
                info@imaginevisuals.com
              </a>{" "}
              <br />
              <div class="font-weight-bold">+41 77 486 07 44</div>
              {/* <br /> */}
              {/* <div class="pt-3" style={isMobile ? { fontSize: 20 } : { fontSize: 24 }}>MwSt. CHE-206.564.093</div> */}
              {isMobile ? (
                <NavLink
                  to="/offerte"
                  fluid
                  class="btn btn-outline-dark btn-lg rounded-0 px-4 mt-5 mb-1 font-weight-bold"
                >
                  Offerte anfordern
                </NavLink>
              ) : undefined}
              {/* <br /> */}
              <div class="division my-5" />
              {/* <br /> */}
              <h2>
                <i>Ihr Ansprechpartner</i>
              </h2>
              <div class="pt-2 pb-0">Mindaugas Chris Petrosius</div>
              <div
                style={isMobile ? { fontSize: 20 } : { fontSize: 24 }}
                class="pt-0 pb-0"
              >
                Creative Director | Architekt
              </div>
              <div
                style={isMobile ? { fontSize: 20 } : { fontSize: 24 }}
                class={isMobile ? "pb-4" : "pt-3"}
              >
                {/* <span> | </span> */}
                <a
                  class="link font-weight-bold"
                  href="mailto:petrosius@imaginevisuals.com"
                >
                  petrosius@imaginevisuals.com
                </a>
                <br />
                {/* <a
                  class="link font-weight-bold"
                  href="https://linkedin.com/in/mpetrosius"
                  target="_blank"
                >
                  Linkedin
                </a>
                <br /> */}
              </div>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default Contact;
