import React from "react";
import Rapperswil from "./Rapperswil";
import Kirchweg from "./Kirchweg";
import Ossingen from "./Ossingen";
import Weisslingen from "./Weisslingen";
import OfferButtonSticky from "./OfferButtonSticky";
import { Helmet } from "react-helmet";
import SHS44 from "./SHS44";

function Portfolio() {
  return (
    <div>
      {/* <Helmet>
            <title>Portfolio</title>
            <meta
              name="description"
              content="Schauen Sie sich unsere Visualisierungen an."
            />
            <meta
              name="keywords"
              content="portfolio, architektur, visualisierungen, vermarktung, wettbewerbe"
            />
          </Helmet> */}
      <OfferButtonSticky />
      <SHS44 />
      <Rapperswil id="work" />
      <Kirchweg />
      <Ossingen />
      {/* <Weisslingen /> */}
    </div>
  );
}

export default Portfolio;
