import React from "react";
import { Col, Image, Button } from "react-bootstrap";
import { NavLink } from "react-router-dom";

function Logo() {
    let isMobile = window.innerWidth < 1280 || window.innerHeight < 750;
    return (
    <Col>
      <NavLink to="/" className={isMobile ? "logoMob" : "logo"} xs="12">
        <Image
          className="d-block"
          style={
            isMobile ? { width: 165, height: 25 } : { width: 330, height: 50 }
          }
          src="../img/imagine_logo.png"
          alt="imagine visuals logo"
        />
      </NavLink>
    </Col>
  );
}

export default Logo;
