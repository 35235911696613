import React from "react";
import { Row, Col } from "react-bootstrap";
import { Helmet } from "react-helmet";
import OfferButton from "./OfferButton";
import Portfolio from "./Portfolio";
import Offer from "./Offer";
import LogoSticky from "./LogoSticky.js";
import Arrow from "./Arrow.js";

function Imagine() {
  let isMobile = window.innerWidth < 1280 || window.innerHeight < 750;

  return (
    <div>
      <div>
        <Row
          className={isMobile ? "p-2 bg" : "p-5 bg"}
          style={{ height: window.innerHeight - 32 }}
        >
          <Helmet>
            <title>Imagine Visuals - 3D Architektur Visualisierung</title>
            <meta
              name="description"
              content="Architektur-Visualisierungen für Vermarktung und Wettbewerbe."
            />
            <meta
              name="keywords"
              content="architektur, visualisierungen, vermarktung, wettbewerbe"
            />
          </Helmet>

          {isMobile ? undefined : <OfferButton />}

          <Col className={isMobile ? "textBigMob" : "textBig"}>
            <p className={isMobile ? "p-3 center" : "center"} style={isMobile ? { maxWidth:'600px' } : { maxWidth:'700px' }}>
              Wir schaffen bezaubernde Visualisierungen für den Immobilienmarkt,
              die die reichste Fantasie anregen. 
              Unsere professionelle Erfahrung
              umfasst Architektur, Innenraumgestaltung, Fotografie und
              Visualisierung. 
              {/* Im heutigen Immobilienmarkt ist Image alles. <br />
              Die beeindruckende Sprache von schönen Bildern steigert Ihr Profil und
              Ihre Projekte auf höheren Niveau und somit hebt Sie deutlich von
              der Konkurrenz ab. */}
              {/* <div
                style={{ cursor: "pointer" }}
                onClick={() =>
                  window.scrollTo({
                    top: window.innerHeight - 16,
                    behavior: "smooth",
                  })
                }
                class="link"
              >
                <i>Mehr als tausend Worte</i>
              </div> */}
              {/* <i>Ein Bild sagt mehr als tausend Worte</i> */}
              {/* <i>„Ein Bild sagt mehr als tausend Worte“</i><br /> */}
              {/* <i>More Than a Thousand Words</i> */}
              {/* <i>One Look is Worth a Thousand Words</i> */}
              {/* <i>„One Look Is Worth a Thousand Words“</i> */}
              {/* “One Picture is Worth Ten Thousand Words” */}
              {/* <i>One Look is Worth Ten Thousand Words</i> */}
              {/* <i>One Picture is Worth Ten Thousand Words</i> */}
              {/* <i>„One Look is Worth Ten Thousand Words”</i> */}
              {/* "A thousand words leave not the same deep impression as does a single image." */}
            </p>
          </Col>
        </Row>
      </div>
      <div>
        {isMobile ? undefined : <LogoSticky />}
        <Row>
          <Portfolio />
          {isMobile ? undefined : <Arrow />}
        </Row>
        <Offer />
      </div>
    </div>
  );
}

export default Imagine;
