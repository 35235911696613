import React from "react";

function Arrow() {
  return (
    <div
      class="arrowDown"
      onClick={() =>
        window.scrollTo({ top: window.innerHeight - 16, behavior: "smooth" })
      }
    >
      <img class="" src="../img/down_arrow_black.png" alt="arrow"></img>
    </div>
  );
}

export default Arrow;
