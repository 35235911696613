import React from "react";
import { NavLink } from "react-router-dom";

function Hamburber() {
    let isMobile = window.innerWidth < 1280 || window.innerHeight < 750
    return (
    <NavLink
      to="/menu"
      className={
        isMobile ? "offerBtnMob buttonAbsoluteMob" : "offerBtn buttonAbsolute"
      }
    >
      <div className="btnText">Menu</div>
    </NavLink>
  );
}

export default Hamburber;
