
import React, { useState } from 'react';
import { Row, Col } from 'react-grid-system'
import {Helmet} from "react-helmet";

function Offer() {
    
    let isMobile = window.innerWidth < 1280 || window.innerHeight < 750;
    
    let [luft, setLuft] = useState(0)
    let [aussen, setAussen] = useState(0)
    let [innen, setInnen] = useState(0)

    function submitForm(e) {
        e.target.submit()
        window.open("/gesendet", "_blank")
    }
    
    return (
        
        <Row className={isMobile ? "bp-2 bg" : "bg" }  style={{height:window.innerHeight-32}}>
            {/* <Helmet>
            <title>Offerte anfordern</title> 
            <meta
              name="description"
              content="Fordern Sie die Offerte schnell und einfach an."
            />
            <meta
              name="keywords"
              content="offerte, architektur, visualisierungen, vermarktung, wettbewerbe"
            />
          </Helmet> */}
            
            <Col className={ isMobile ? "textMob" : "text"} >
            {isMobile ? undefined
            :
            <button type="submit" form="gform" class="btn btn-dark btn-lg rounded-0 sendBtn px-4">Anfrage Senden</button>
            }
            
                <div className={ isMobile ? "form-container-mob" : "form-container center" }>
                <form id="gform" onSubmit={submitForm} action="https://docs.google.com/forms/u/3/d/e/1FAIpQLSc0-YRaYDgQtQSwcUnPSfmuRdhklrG6LpEQG6fqLBq9wXyxZw/formResponse" method="post">
                    
                    <div class="row">

                        <div class="col-lg-6 p-xl-5">
                            <div class="form-label ">Kontakt</div>
                            <div class="label-underline"></div>
                            <div class="row line">
                                <div class="col-md-6">
                                    <input type="text" name="entry.2046991153" class="form-control" id="input1" placeholder="Name*" required/>
                                </div>
                                <div class="col-md-6">
                                    <input type="text" name="entry.2050913281" class="form-control" id="input2" placeholder="Firma*" required/>
                                </div>
                            </div>
                            <div class="row line">
                                <div class="col-md-6">
                                    <input type="email" name="entry.1811198520" class="form-control" id="input3" placeholder="Mail*" required/>
                                </div>
                                <div class="col-md-6">
                                    <input type="text" name="entry.1173897795" class="form-control" id="inputEmail4" placeholder="Telefon" />
                                </div>
                            </div>
                            <div class="line">
                                <div class="form-label pt-md-3 pt-4">Termine</div>
                                <div class="label-underline"></div>
                            </div>
                            <div class="row line">
                                <div class="col-sm-6">
                                    <input type="text" name="entry.537012168" class="form-control" placeholder="Datenübergabe" onFocus={(e) => e.target.type = 'date'}/>
                                </div>
                                <div class="col-md-6">
                                    <input type="text" name="entry.314610152" class="form-control" placeholder="Abgabe Visualisierungen" onFocus={(e) => e.target.type = 'date'}/>
                                </div>
                            </div>
                            <div class="line">
                                <div class="form-label pt-md-3 pt-4">Projektbeschrieb</div>
                                <div class="label-underline"></div>
                            </div>
                            <div class="row line">
                                <div class="col-md-12">
                                    <input type="text" name="entry.1010890295" class="form-control" id="" placeholder="Ihr Projektname" />
                                    <textarea name="entry.1213786777" class="form-control mt-3" placeholder="Erzählen Sie uns über Ihr Projekt" cols="45" rows="3"></textarea>
                                </div>
                            </div>
                    </div>

                    <div class="form-group col-lg-6 pt-4 pt-lg-0 p-xl-5">
                        <div class="form-label pt-md-0 pt-3">Projektangaben</div>
                        <div class="label-underline"></div>
                            <fieldset class="form-group">
                                <div class="row pt-2">
                                    <legend class="col-sm-6 pt-md-2 pt-4">Ausgangslage</legend>
                                    <div class="col-sm-6 pt-2">
                                        <div class="d-flex pb-2">
                                            <label class="form-check-label" for="i13">
                                                    Vermarktung
                                                </label>
                                            <div class="ml-auto pr-5 ">
                                                <input name="entry.268907102" class="form-check-input" type="checkbox" value="Vermarktung" id="i13" />
                                            </div>
                                        </div>

                                        <hr />
                                                    
                                        <div class="d-flex py-2">
                                            <label class="form-check-label" for="i17">
                                                Wettbewerb
                                            </label>
                                            <div class="ml-auto pr-5">
                                                <input name="entry.268907102" class="form-check-input" type="checkbox" value="Wettbewerb" id="i17" />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </fieldset>

                            <hr />

                            <div class="row">
                                <legend class="col-12 col-sm-6 pt-md-2 pt-4">Anzahl Bilder</legend>
                                <div class="col-12 col-sm-6 pt-1">
                                    <div class="row">
                                        <div class="col-5 ">
                                            <label class="pt-2">Luft</label>
                                        </div>
                                        <div class="col-7 d-flex pt-2">
                                            <div class="pt-1 font-weight-bold numbersCount" >{luft}</div> 
                                            <input type="text" name="entry.903238333" class="form-control noborder hidden" style={{height:0, width:0}} id="" placeholder={luft} value={luft}/>
                                            <div class="ml-auto d-flex">
                                                <div onClick={luft ? () => setLuft(luft - 1) : undefined} class="btnRound btn-outline-secondary mr-1 noselect">−</div>
                                                <div onClick={() => setLuft(luft + 1)} class="btnRound btn-outline-secondary noselect">+</div>
                                            </div>
                                        </div>
                                    </div>

                                    <hr />

                                    <div class="row">
                                        <div class="col-5">
                                            <label class="pt-2">Aussen</label>
                                        </div>
                                        <div class="col-7 d-flex pt-2">
                                            <div class="pt-1 font-weight-bold numbersCount" >{aussen}</div> 
                                            <input type="text" name="entry.285489835" class="form-control noborder hidden" style={{height:0, width:0}} id="" placeholder={aussen} value={aussen}/>
                                            <div class="ml-auto d-flex">
                                                <div onClick={aussen ? () => setAussen(aussen - 1) : undefined} class="btnRound btn-outline-secondary mr-1 noselect">−</div >
                                                <div onClick={() => setAussen(aussen + 1)} class="btnRound btn-outline-secondary noselect">+</div >
                                            </div>
                                        </div>
                                    </div>

                                    <hr />

                                    <div class="row pb-1">
                                        <div class="col-5">
                                            <label class="pt-2 ">Innen</label>
                                        </div>
                                        <div class="col-7 d-flex pt-2">
                                            <div class="pt-1 font-weight-bold numbersCount" >{innen}</div> 
                                            <input type="text" name="entry.335067000" class="form-control noborder hidden" style={{height:0, width:0}} id="" placeholder={innen} value={innen}/>
                                            <div class="ml-auto d-flex">
                                                <div onClick={innen ? () => setInnen(innen - 1)  : undefined} class="btnRound btn-outline-secondary mr-1 noselect">−</div >
                                                <div onClick={() => setInnen(innen + 1)} class="btnRound btn-outline-secondary noselect">+</div >
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <hr />

                            <fieldset class="form-group">
                                <div class="row">
                                    <legend class="col-sm-6 pt-md-2 pt-4">Vorhandenes Material</legend>
                                    <div class="col-sm-6 py-2">
                                        <div class="d-flex pt-1 pb-2">
                                            <label class="form-check-label" for="i34">
                                                3D Modell
                                            </label>
                                            <div class="ml-auto pr-5">
                                                <input name="entry.28265895" value="3D Modell" class="form-check-input" type="checkbox" id="i34" />
                                            </div>
                                        </div>

                                        <hr />
                                
                                        <div class="d-flex pb-0 pt-2">
                                            <label class="form-check-label" for="i38">
                                                Fotos vor Ort
                                            </label>
                                            <div class="ml-auto pr-5">
                                                <input name="entry.28265895" value="Fotos vor Ort" class="form-check-input" type="checkbox" id="i38" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>

                            <hr />

                            <div class="row">
                                <legend class="col-sm-6 pt-md-2 pt-4">Dateien</legend>
                                <div class="col-sm-6 pt-2">
                                    <input type="text" name="entry.296406373" class="form-control" id="" placeholder="Link (dropbox, etc)" />
                                </div>
                            </div>
                        </div>
                    </div>
                    </form>

                    {isMobile ? 
                    <button type="submit" form="gform" class="btn btn-block btn-dark btn-lg rounded-0 px-4 my-5 font-weight-bold">Anfrage Senden</button>
                    : undefined
                    }

                </div>
            </Col>
        </Row>

    )
}

export default Offer
