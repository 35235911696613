import React from "react";
import { Row, Col } from "react-grid-system";
import { Helmet } from "react-helmet";
import { NavLink } from "react-router-dom";

import OfferButton from "./OfferButton";

function About() {
  let isMobile = window.innerWidth < 1280 || window.innerHeight < 750
  let isSmall = window.innerWidth < 1600;

  return (
    <Row
      className={isMobile ? "py-3 bgMob" : "p-5 bg"}
      style={{ height: window.innerHeight - 32 }}
    >
      <Helmet>
        <title>Profil</title>
        <meta
          name="description"
          content="Erfahren Sie mehr über uns"
        />
        <meta name="keywords" content="profil, ansatz, methodik, prozess" />
      </Helmet>

      {isMobile ? undefined : <OfferButton />}

      <Col class="p-0 m-0">
        <Row
          className={isMobile ? "py-5 bg" : "py-5 bg center"}
          style={{ maxWidth: 1600 }}
        >

          <Col>
            <Row>
              <div
                className={
                  isMobile
                    ? "textBigMob pt-5 col-12"
                    : "textBig col-12 col-md-6 pr-5 pb-5"
                }
              >
                <div class="" style={isSmall ? { fontSize:24} : { fontSize:32}}>
                Wir schaffen bezaubernde Visualisierungen für den Immobilienmarkt,
              die die reichste Fantasie anregen.
                </div>
              </div>
              <div
                className={
                  isMobile
                    ? "textBigMob col-12"
                    : "textBig col-12 col-md-6 pl-5"
                }
              >
                <div class="" style={isSmall ? { fontSize:24} : { fontSize:32}}>
                Unsere professionelle Erfahrung
              umfasst Architektur, Innenraumgestaltung, Fotografie und
              Visualisierung.
              </div>
              </div>
            </Row>
            <Row>
              <div
                className={
                  isMobile
                    ? "textBigMob pt-5 col-12"
                    : "textBig col-12 col-md-6 pr-5 pt-2"
                }
              >
                <h2 class=" font-italic">
                  Erzählung einer Geschichte
                </h2>
                <div class="division" />
                <h2>
                  Von Anfang an arbeiten wir eng mit allen Beteiligten zusammen,
                  um eine klare und starke Vision für das Ergebnis zu
                  entwickeln. Wir suchen nach den besten Wegen und Blickwinkeln,
                  um die überzeugendste Geschichte zu erzählen.
                </h2>
              </div>
              <div
                className={
                  isMobile
                    ? "textBigMob pt-5 col-12"
                    : "textBig col-12 col-md-6 pl-5 pt-2"
                }
              >
                <h2 class=" font-italic">Komposition von Ansichten</h2>
                <div class="division" />
                <h2>
                  Den Prozess der Bildproduktion betrachten wir als
                  redaktionelles Fotoshooting für ein Magazin. Ein guter
                  Fotograf erkennt fesselnde Kompositionen – unvorgesehene
                  Perspektiven erwachen zum Leben.
                </h2>
              </div>
            </Row>
            <Row>
              <div
                className={
                  isMobile
                    ? "textBigMob pt-5 col-12"
                    : "textBig col-12 col-md-6 pr-5 pt-2"
                }
              >
                <h2 class=" font-italic">Gestaltung des Raumes</h2>
                <div class="division" />
                <h2>
                  Elemente in einem Bild können es verbessern oder zerstören. Es
                  ist eine eigene Kunstform, ein sauberes und elegantes, aber
                  gleichzeitig fesselndes Bild zu schaffen. Talentierte
                  Innenarchitekten und Stylisten evozieren Träume.
                </h2>
              </div>
              <div
                className={
                  isMobile
                    ? "textBigMob pt-5 col-12"
                    : "textBig col-12 col-md-6 pl-5 pt-2"
                }
              >
                <h2 class=" font-italic">Visualisierung des Bildes</h2>
                <div class="division" />
                <h2>
                  Wir verwenden modernste Technik, aber wie ein gutes Gemälde
                  einen guten Künstler braucht, braucht ein gutes Rendering
                  einen guten Technologen. Die besten Visualisierer sind
                  erfahrene Technologen und grossartige Künstler.
                </h2>
              </div>
            </Row>
          </Col>
          {isMobile ? (
            <NavLink
              to="/offerte"
              fluid
              class="btn btn-dark btn-block btn-lg rounded-0 px-4 mx-5 mt-5 mb-1 font-weight-bold"
            >
              Offerte anfordern
            </NavLink>
          ) : undefined}
        </Row>
      </Col>

    </Row>
  );
}

export default About;
