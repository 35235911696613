import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {

    const y = window.innerHeight-16
        if (pathname === '/work' || pathname === '/werk'  || pathname === '/portfolio') {
            window.scrollTo({top: y, behavior: 'smooth'});
        // } else if (pathname == '/ossingen') {
        //     document.getElementById('ossingen').scrollIntoView();
        } else {
            window.scrollTo(0, 0);
        }
    }, [pathname]);

  return null;
}