import React from "react";
import { Row, Col } from "react-grid-system";
import { Helmet } from "react-helmet";
import { NavLink } from "react-router-dom";
import OfferButton from "./OfferButton";

function Products() {
  let isMobile = window.innerWidth < 1280 || window.innerHeight < 750;

  return (
    <Row
      className={isMobile ? "py-5 bgMob" : "p-5 bg"}
      style={{ height: window.innerHeight - 32 }}
    >
      <Helmet>
        <title>Produkte</title>
        <meta name="description" content="Unseres Angebot." />
        <meta name="keywords" content="Produkte" />
      </Helmet>

      {isMobile ? undefined : <OfferButton />}

      <Col className={isMobile ? "p-0 m-0" : ""}>
        <Row
          className={isMobile ? "py-5 bg" : "center"}
          style={{ maxWidth: 1600 }}
        >
          <div
            className={
              isMobile ? "textBigMob p-3 col-12 col-md-6" : "textBig col-12 col-md-6 p-5"
            }
          >
            <img
              className="img-fluid pb-4"
              src="../img/rapperswil/immobilien-vermarktung-visualisierung-architektur-1c.jpg"
              alt="Vermarktungs-Visualisierung"
            />
            <h1 class="pb-2">Vermarktungs-Visualisierungen</h1>
            <div class="division" />
            <h2>
              Wir lieben bezaubernde Visualisierungen für Immobilien zu
              schaffen, die die reichste Fantasie anregen.
            </h2>
            <div class="division" />
            <h2 class="pt-0">
              <span class="font-italic">Prozess: </span>
              <a
                class="link font-weight-bold "
                href="/Supreme_Workflow_DE.pdf"
                target="_blank"
              >
                Supreme Workflow
              </a>
              <span> oder </span>
              <a
                class="link font-weight-bold"
                href="/Simplified_Workflow_DE.pdf"
                target="_blank"
              >
                Simplified Workflow
              </a>
            </h2>
          </div>
          <div
            className={
              isMobile ? "textBigMob pt-5 pt-md-3 p-3 col-12 col-md-6" : "textBig col-12 col-md-6 p-5"
            }
          >
            <img
              className="img-fluid pb-4"
              src="../img/weisslingen/Wettbewerb-Visualisierung-Kiga-Weisslingen-1c.jpg"
              alt="Vermarktungs-Visualisierung"
            />
            <h1 class="pb-2">Wettbewerbs-Visualisierungen</h1>
            <div class="division" />
            <h2>
              Wir sind Architekten und lieben die Bilder für Wettbewerbe zu
              erstellen, ob künstlerisch oder realistisch.
            </h2>
            <div class="division" />
            <h2 class="pt-0">
              <span class="font-italic">Prozess: </span>
              <a
                class="link font-weight-bold "
                href="/Supreme_Workflow_DE.pdf"
                target="_blank"
              >
                Supreme Workflow
              </a>
            </h2>
          </div>
          {isMobile ? (
            <NavLink
              to="/offerte"
              fluid
              class="btn btn-dark btn-block btn-lg rounded-0 px-4 mx-5 mt-5 mb-1 font-weight-bold"
            >
              Offerte anfordern
            </NavLink>
          ) : undefined}
        </Row>
      </Col>
    </Row>
  );
}

export default Products;
