import React from 'react'
import {Col, Image, Button } from 'react-bootstrap'
import {NavLink} from 'react-router-dom'

import OfferButton from "./OfferButton";


function LogoSticky() {
    let isMobile = window.innerWidth < 1280 || window.innerHeight < 750;
    return (
        <div className={isMobile ? "logoStickyMob" : "logoSticky"}>
            <div class="logoPusher"></div>
            <div class="logoStickyImg" onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>
                <img
                    style={isMobile ? {width:165, height:25} : {width:330, height:50}}
                    src="../img/imagine_logo.png"
                    alt="imagine visuals logo"
                />
            </div>
        </div>
    )
}

export default LogoSticky
