import React from "react";
import { Row } from "react-bootstrap";
import { NavLink, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Nav() {
    let isMobile = window.innerWidth < 1280 || window.innerHeight < 750;
    var classNav = isMobile ? "navLinkMob" : "navLink";

  return (
    <Row
      className=""
      style={
        isMobile
          ? { position: "absolute", bottom: 30 }
          : { position: "absolute", bottom: 50, right: 100 }
      }
    >
      <nav className={isMobile ? "navMob" : "nav"}>
        <NavLink
          className={classNav}
          activeStyle={{ color: "rgb(142, 142, 147)" }}
          to="/portfolio"
        >
          Portfolio
        </NavLink>
        {/* <NavLink className={classNav} activeStyle={{ color: "rgb(142, 142, 147)" }} to="/werk">Wettbewerb</NavLink> */}
        {/* <NavLink
          className={classNav}
          activeStyle={{ color: "rgb(142, 142, 147)" }}
          to="/produkte"
        >
          Produkte
        </NavLink> */}

        {/* <NavLink
          className={classNav}
          activeStyle={{ color: "rgb(142, 142, 147)" }}
          to="/profil"
        >
          Profil
        </NavLink> */}
          <NavLink
            className={classNav}
            activeStyle={{ color: "rgb(142, 142, 147)" }}
            to="/referenzen"
          >
            Referenzen
          </NavLink>
        <NavLink
          className={classNav}
          activeStyle={{ color: "rgb(142, 142, 147)" }}
          to="/offerte"
        >
          Offerte
        </NavLink>
        <a
          className={classNav}
          activeStyle={{ color: "rgb(142, 142, 147)" }}
          href="https://minde.ch"
          target="_blank" 
          rel="noopener noreferrer"
        >
          Branding
        </a>
        <NavLink
          className={classNav}
          activeStyle={{ color: "rgb(142, 142, 147)" }}
          to="/kontakt"
        >
          Kontakt
        </NavLink>
      </nav>
    </Row>
  );
}

export default Nav;
