
import React, { useState } from 'react';
import { Row, Col } from 'react-grid-system'
import { useTranslation } from "react-i18next"
import {Helmet} from "react-helmet";



function Submited() {

    // const { t, i18n } = useTranslation();
    let isMobile = window.innerWidth < 1280 || window.innerHeight < 750;
    
    return (
        
        <Row className={isMobile ? "p-2 bg" : "bg" }  style={{height:window.innerHeight-32}}>
            
            <Col className={ isMobile ? "textBigMob" : "textBig"} >
            
                <div className={ isMobile ? "p-3 center" : "center" }>
                
                <p className={ isMobile ? "text-center" : "center text-center"} >
                    Ihre Anfrage wurde erfolgreich gesendet! <br />
                    Wir melden uns so schnell wie möglich bei Ihnen.
                </p>
   
                </div>
            </Col>

        </Row>

    )
}

export default Submited
