import 'bootstrap/dist/css/bootstrap.css';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './i18n'
import ReactGA from 'react-ga';
ReactGA.initialize('UA-151376244-1');
ReactGA.pageview(window.location.pathname + window.location.search);


// ReactDOM.render( // we are in the server
//   <React.StrictMode>
//     <Suspense fallback={<div></div>}>
//       <App />
//     </Suspense>
//   </React.StrictMode>,
//   document.getElementById('root')
// );


const rootElement = document.getElementById("root");

if (rootElement.hasChildNodes()) {
  ReactDOM.hydrate(  // we are in the browser
    <React.StrictMode>
      {/* <Suspense fallback={<div></div>}> */}
        <App />
      {/* </Suspense> */}
    </React.StrictMode>,
    document.getElementById('root')
  ); 
} else {
  ReactDOM.render( // we are in the server
    <React.StrictMode>
      {/* <Suspense fallback={<div></div>}> */}
        <App />
      {/* </Suspense> */}
    </React.StrictMode>,
    document.getElementById('root')
  );
}



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
