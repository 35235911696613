import React, { useState, useEffect } from "react";
import { Container, Row, Image, Media } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import LazyLoad from "react-lazyload";

function Kirchweg() {
  const { t, i18n } = useTranslation();
  let isMobile = window.innerWidth < 1280 || window.innerHeight < 750;
  const [textToggle, textToggleState] = useState(false);
  useEffect(() => {
    if (isMobile) {
      textToggleState(!textToggle);
    }
  }, []);

  return (
    <Container id="kirchweg" fluid className="main" style={{}}>
      <div className={isMobile ? "stickyMob" : "sticky"}>
        <div
          style={
            isMobile
              ? {}
              : { backgroundColor: "transparent", cursor: "default" }
          }
          className={textToggle ? "plusBox" : "infoBox"}
          onClick={isMobile ? () => textToggleState(!textToggle) : undefined}
        >
          {isMobile ? (
            <div
              style={textToggle ? {} : { display: "none" }}
              class="plusSymbol"
            >
              +
            </div>
          ) : undefined}
          <div style={textToggle ? { display: "none" } : {}}>
            {isMobile ? <h4>✕</h4> : undefined}

            <h5>{t("info.project")}</h5>
            <h6>Kirchweg</h6>

            <h5>{t("info.location")}</h5>
            <h6>Bern, Schweiz</h6>

            <h5>{t("info.developer")}</h5>
            <a href="https://www.burckhardtentwicklungen.ch" target="_blank" className="info">
              <h6>Burckhardt Entwicklungen</h6>
            </a>

            <h5>{t("info.architecture")}</h5>
            <a
              href="https://www.burckhardtpartner.com/"
              target="_blank"
              className="info"
            >
              <h6>Burckhardt+Partner</h6>
            </a>

            <h5>{t("info.interior")}</h5>
            <a
              href="https://www.burckhardtpartner.com/"
              target="_blank"
              className="info"
            >
              <h6>Burckhardt+Partner</h6>
            </a>

            <h5>{t("info.styling")}</h5>
            <h6>Imagine Visuals</h6>
          </div>
        </div>
      </div>

      <Row>
        <Media className="px-3 pb-3">
          <LazyLoad offset={200}>
            <Image
              fluid
              className=""
              src="../img/kirchweg/immobilien-vermarktung-visualisierung-architektur-abend-b.jpg"
              alt="Immobilien Vermarktung Visualisierung - Architektur am Abend"
            />
          </LazyLoad>
        </Media>
      </Row>

      <Row>
        <Media className="px-3 pb-3">
          <LazyLoad offset={200}>
            <Image
              fluid
              className=""
              src="../img/kirchweg/immobilien-vermarktung-visualisierung-innenraum-0b.jpg"
              alt="Immobilien Vermarktung Visualisierung - Erdgeschoss"
            />
          </LazyLoad>
        </Media>
      </Row>

      <Row>
        <Media className="px-3 pb-3">
          <LazyLoad offset={200}>
            <Image
              fluid
              className=""
              src="../img/kirchweg/immobilien-vermarktung-visualisierung-innenraum-1b.jpg"
              alt="Immobilien Vermarktung Visualisierung - Erste Obergeschoss"
            />
          </LazyLoad>
        </Media>
      </Row>

      <Row>
        <Media className="px-3 pb-3">
          <LazyLoad offset={200}>
            <Image
              fluid
              className=""
              src="../img/kirchweg/immobilien-vermarktung-visualisierung-architektur-morgen-b.jpg"
              alt="Immobilien Vermarktung Visualisierung - Architektur am Morgen"
            />
          </LazyLoad>
        </Media>
      </Row>

      <Row>
        <Media className="px-3 pb-3">
          <LazyLoad offset={200}>
            <Image
              fluid
              className=""
              src="../img/kirchweg/immobilien-vermarktung-visualisierung-innenraum-2.jpg"
              alt="Immobilien Vermarktung Visualisierung - Zweite Obergeschoss"
            />
          </LazyLoad>
        </Media>
      </Row>

      <Row>
        <Media className="px-3 pb-3">
          <LazyLoad offset={200}>
            <Image
              fluid
              className=""
              src="../img/kirchweg/immobilien-vermarktung-visualisierung-innenraum-3.jpg"
              alt="Immobilien Vermarktung Visualisierung - Dritte Obergeschoss"
            />
          </LazyLoad>
        </Media>
      </Row>

      <Row>
        <Media className="px-3 pb-3">
          <LazyLoad offset={200}>
            <Image
              fluid
              className=""
              src="../img/kirchweg/immobilien-vermarktung-visualisierung-innenraum-4.jpg"
              alt="Immobilien Vermarktung Visualisierung - Dachgeschoss"
            />
          </LazyLoad>
        </Media>
      </Row>

      <Row>
        <Media className="px-3 pb-3">
          <LazyLoad offset={200}>
            <Image
              fluid
              className=""
              src="../img/kirchweg/immobilien-vermarktung-visualisierung-innenraum-2b.jpg"
              alt="Immobilien Vermarktung Visualisierung - Zweite Obergeschoss"
            />
          </LazyLoad>
        </Media>
      </Row>

      <Row>
        <Media className="px-3 pb-3">
          <LazyLoad offset={200}>
            <Image
              fluid
              className=""
              src="../img/kirchweg/immobilien-vermarktung-visualisierung-innenraum-3b.jpg"
              alt="Immobilien Vermarktung Visualisierung - Dritte Obergeschoss"
            />
          </LazyLoad>
        </Media>
      </Row>

      <Row>
        <Media className="px-3 pb-3">
          <LazyLoad offset={200}>
            <Image
              fluid
              className=""
              src="../img/kirchweg/immobilien-vermarktung-visualisierung-innenraum-4b.jpg"
              alt="Immobilien Vermarktung Visualisierung - Dachgeschoss"
            />
          </LazyLoad>
        </Media>
      </Row>
    </Container>
  );
}

export default Kirchweg;
